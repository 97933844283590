import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.css']
})
export class UpcomingEventsComponent implements OnInit {
	viewQueue: any[]=[];

  constructor(private router:Router) { }

  ngOnInit() {
  }
  goToEvent(eventName:String){
    if(eventName == "colorcoded"){
          this.router.navigateByUrl('/colorcoded');

    }
    else if(eventName == "summersplash"){
          this.router.navigateByUrl('/summersplash');

    }
    else if(eventName == "clubsummer"){
          this.router.navigateByUrl('/clubsummer');

    }
    else if(eventName == "foamfete"){
    this.router.navigateByUrl('/foamfete');

    }
    else if(eventName == "coming-soon"){
      this.router.navigateByUrl('/coming-soon');
    }
    else{
      console.log(`unrecognized eventname: ${eventName}`);
    }
  }
}
