import { Component, OnInit } from '@angular/core';
import {OrderItem} from '../models/OrderItem';

@Component({
  selector: 'app-shop-page',
  templateUrl: './shop-page.component.html',
  styleUrls: ['./shop-page.component.css']
})
export class ShopPageComponent implements OnInit {
	items:OrderItem[]=[];
	total:number=0;
  constructor() { }

  ngOnInit() {
  }
  addItemtoCart(item:OrderItem):void{
  	this.items.push(item);
    this.total=this.total+item.price;
  }
  removeItemFromCart(item:OrderItem, index:number):void{
    this.items.splice(index,1);
    this.total=this.total-item.price;
  }
  

}
