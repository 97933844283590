import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {AuthService } from '../services/auth.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  justLoggedIn:boolean=false;
  constructor(private auth: AuthService, private ref: ChangeDetectorRef) { }

  ngOnInit() {
  }
  logOut(){
  	this.auth.signOut();
  }
  signInwithGoogle(): void{
    console.log('Clicked');
  	this.auth.googleLogin()
  	.then(()=>this.afterSignIn());
  }
  afterSignIn(): void{
    console.log(`welcome ${this.auth.currentUser.displayName}`);
    this.toggleMessage();
  }
  toggleMessage(){
    console.log("toggle called");
    this.justLoggedIn=true;
    this.ref.detectChanges();
    setTimeout(()=>{
      this.justLoggedIn=false;
      this.ref.detectChanges();
    },2500);
  }
  async checkAuth() {
   const user = await this.auth.isLoggedIn();
   if (user) {
    //do something
   } else {
     //do something
  }
  this.ref.detectChanges();
  this.ref.reattach();
}
 
}
