import { Component, OnInit } from '@angular/core';
import {OrderService} from '../services/order.service';
import {EmailService} from '../services/email.service';
import {OrderItem} from '../models/OrderItem';
@Component({
  selector: 'app-shop-item-page',
  templateUrl: './shop-item-page.component.html',
  styleUrls: ['./shop-item-page.component.css']
})
export class ShopItemPageComponent implements OnInit {

	imageSrc:string="";
	title:string="";
	price:number=0;
	stock:number;

  constructor(private orderService:OrderService, private emailService:EmailService) { }

  ngOnInit() {
  }
  addItemToCart(){
  	//let order:OrderItem= new OrderItem();
  }

}
