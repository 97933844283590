import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
	viewQueue: any[]=[];

  constructor(private router:Router) { }

  ngOnInit() {
  }
  next(){

  }
  previous(){
  	
  }
  goToEvent(){
    this.router.navigateByUrl('/colorcoded');
  }
}
