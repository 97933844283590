import { Directive, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMyAutoPlay]'
})
export class MyAutoPlayDirective implements OnInit{

  constructor(public element: ElementRef) { }

 public ngOnInit(): void {
    let vid = this.element.nativeElement;
    vid.muted = true;
    vid.play();
  }
}
