import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import {Router} from '@angular/router'


interface User {
  uid: string;
  email?: string | null;
  photoURL?: string;
  displayName?: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  user: Observable<User | null>;
  justLoggedIn:boolean=false;

	@Input() hasVideo: boolean;
  @Input() menu:boolean
  @Input() isWelcome:boolean
  @Output() menuClicked = new EventEmitter<boolean>();

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
        this.user=this.auth.user;
  }

 logOut(){
    this.hideMenu();
    this.auth.signOut();
  }
  signInwithGoogle(): void{
    this.hideMenu();
    console.log('Clicked');
    this.auth.googleLogin()
    .then(()=>this.afterSignIn());
  }
  afterSignIn(): void{
    console.log(`welcome ${this.auth.currentUser.displayName}`);
    this.toggleMessage();
  }
  toggleMessage(){
    console.log("ToggleMessage invoked: Show pop up after sign IN");
     
  }
  toggleMenu(){
    console.log("Menu clicked");
    this.menu=!this.menu;
    this.menuClicked.emit(this.menu);
  }
  hideMenu(){
    this.menu=false;
     this.menuClicked.emit(this.menu);
  }
  route(route:String){
    this.router.navigate([`/${route}`]);

  }
  
}
