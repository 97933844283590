import { NgModule } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AuthGuard} from './auth.guard';
@NgModule({
  imports: [
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence()
  ],
  providers: [AuthService, AuthGuard]
})
export class CoreModule { }