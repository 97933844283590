import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
	endpoint = 'https://us-central1-pardiikidz-f8dde.cloudfunctions.net/sendEmail';
  name:string;
  email:string;
  message:string;
  number:string;
  subject:string="I have a question";
  inProgress:boolean=false;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }
    sendEmail() {

    const data = {
      name: this.name,
      email: this.email,
      message: this.message,
      subject: this.subject,
      number: this.number
    }
    console.log(data);
    return this.http.post(this.endpoint,data);
  }
  onSubmit(){
    this.inProgress=true;
    this.sendEmail().subscribe((res)=>{
        console.log(`Success: ${res}`);
        this.inProgress=false;
        this.clearForm();

    }, 
    err=>{
      console.log(`Error ${err}`);
      this.inProgress=false;
      this.clearForm();
    });
  }
  clearForm(){
    this.name="";
    this.email="";
    this.subject="";
    this.message="";
    this.number="";
  }

}
