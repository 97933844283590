import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {AuthService} from './services/auth.service';


import { AppComponent } from './app.component';
import { FAQComponent } from './faq/faq.component';
import { CinemaComponent } from './cinema/cinema.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ChatroomComponent } from './chatroom/chatroom.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HomeComponent } from './home/home.component';
import { WelcomeHeroComponent } from './welcome-hero/welcome-hero.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';

// @angular/fire/ Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

// environment
import { environment } from '../environments/environment';
import { ContactComponent } from './contact/contact.component';
import { EventpageComponent } from './eventpage/eventpage.component';

//
import {CoreModule} from './core/core.module';
import { ChallengesComponent } from './challenges/challenges.component';
import { MainComponent } from './main/main.component';
import { FormsPageComponent } from './forms-page/forms-page.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule }   from '@angular/forms';
import { MyAutoPlayDirective } from './my-auto-play.directive';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ActionsComponent } from './actions/actions.component';
import { ShopPageComponent } from './shop-page/shop-page.component';
import { ShopItemPageComponent } from './shop-item-page/shop-item-page.component';

@NgModule({
  declarations: [
    AppComponent,
    FAQComponent,
    CinemaComponent,
    CalendarComponent,
    ChatroomComponent,
    LeaderboardComponent,
    ComingSoonComponent,
    HomeComponent,
    WelcomeHeroComponent,
    NavbarComponent,
    UpcomingEventsComponent,
    ContactComponent,
    EventpageComponent,
    ChallengesComponent,
    MainComponent,
    FormsPageComponent,
    MyAutoPlayDirective,
    DatepickerComponent,
    ActionsComponent,
    ShopPageComponent,
    ShopItemPageComponent
      ],
  imports: [
  HttpClientModule,
  BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
	
}
