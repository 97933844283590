import { Component, OnInit } from '@angular/core';
import {FaqService} from '../services/faq.service';
import {FrequentlyAskedQuestion} from '../models/FrequentlyAskedQuestion';
import {Observable} from 'rxjs';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
  faqs:Observable<FrequentlyAskedQuestion[]>;
  constructor(private faqService:FaqService ) {
 }

  ngOnInit() {
    this.faqs=this.faqService.FAQs;
  }
  clap(faq){
  	this.faqService.clap(faq);
  }
}
