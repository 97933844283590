import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, startWith, tap, filter } from 'rxjs/operators';
import {FrequentlyAskedQuestion} from '../models/FrequentlyAskedQuestion';


@Injectable({
  providedIn: 'root'
})
export class FaqService {
	FAQcollection:AngularFirestoreCollection<FrequentlyAskedQuestion>;
	FAQs:Observable<FrequentlyAskedQuestion[]>;
  constructor(private afs: AngularFirestore) { 
  	this.FAQcollection=this.afs.collection('FAQs', ref => {
  return ref.orderBy('importance', 'asc')});
  	this.FAQs=this.FAQcollection.valueChanges();
  }
  addFAQ(faq:FrequentlyAskedQuestion): void{
    let uid:string=this.afs.createId();
    console.log(`uid = ${uid}`);
    faq.setUid(uid);
  	this.FAQcollection.doc(uid).set(Object.assign({},faq));
 
  }
  clap(faq:FrequentlyAskedQuestion){
    faq.helpfulness= faq.helpfulness+1;
    console.log(faq.helpfulness);
    this.FAQcollection.doc(faq.uid).update(Object.assign({},faq));

  }
}
