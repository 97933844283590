import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-forms-page',
  templateUrl: './forms-page.component.html',
  styleUrls: ['./forms-page.component.css'],
  animations:[
  trigger('myInsertRemoveTrigger', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('400ms 400ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    animate('400ms', style({ opacity: 0 }))
  ])
])
  ]
})
export class FormsPageComponent implements OnInit {
  isStarted:boolean =false;
  inProgress:boolean =false;
  locale: string = 'en';
  selectedDate: any;
	firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  size:string="Medium";
  quantity:number=1;
  color: string="White"; 
  deliveryAddress: string;
  deliveryDate:string; 
  instructions: string;
  endpoint:string="";
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }
  ngOnSubmit(){
  }

   setSelectedDate(date){
    this.selectedDate = date;
  }

  canChangeMonthLogic(num, currentDate){
    currentDate.add(num, 'month');
    const minDate = moment().add(-1, 'month');
    const maxDate = moment().add(1, 'month');
    
    return currentDate.isBetween(minDate, maxDate);
  }

  isAvailableLogic(dateToCheck: any){
    if(dateToCheck.isBefore(moment(), 'day')||dateToCheck.isAfter(moment().add(30, 'day'), 'day')){
      return false;
      } else {
          return true;
      }
  }
  startForm(){
    this.isStarted=true;
  }
  sendEmail() {
  
    const data = {
      firstName:this.firstName,
      lastName:this.lastName,
      email:this.email,
      phoneNumber:this.phoneNumber,
      size:this.size,
      quantity:this.quantity,
      color:this.color,
      deliveryAddress:this.deliveryAddress,
      deliveryDate:this.selectedDate,
      submissionDate:new Date(),
      instructions:this.instructions
    }
    console.log(data);
    return this.http.post(this.endpoint,data);
  }
  clearForm(){

  }
  onSubmit(){
    this.inProgress=true;
    this.sendEmail().subscribe((res)=>{
        console.log(`Success: ${res}`);
        this.inProgress=false;
        this.clearForm();

    }, 
    err=>{
      console.log(`Error ${err}`);
      this.inProgress=false;
      this.clearForm();
    });
  }
}
