import { Component, OnInit, OnDestroy } from '@angular/core';
import {AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

interface User {
  uid: string;
  email?: string | null;
  photoURL?: string;
  displayName?: string;
}

@Component({
  selector: 'app-welcome-hero',
  templateUrl: './welcome-hero.component.html',
  styleUrls: ['./welcome-hero.component.css']
})
export class WelcomeHeroComponent implements OnInit {
	user: Observable<User | null>;
  constructor(private auth: AuthService) {

   }
  ngOnInit() {
	 this.user=this.auth.user;
  }	
  ngOnDestroy(){

  }

}
