import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {ChatroomComponent} from '../chatroom/chatroom.component';
import {CinemaComponent} from '../cinema/cinema.component';
import {ComingSoonComponent} from '../coming-soon/coming-soon.component';
import {FAQComponent} from '../faq/faq.component';
import {HomeComponent} from '../home/home.component';
import {LeaderboardComponent} from '../leaderboard/leaderboard.component';
import {ContactComponent} from '../contact/contact.component';
import {EventpageComponent} from '../eventpage/eventpage.component';
import {ChallengesComponent} from '../challenges/challenges.component';
import {FormsPageComponent} from '../forms-page/forms-page.component';
import {ActionsComponent} from '../actions/actions.component';

import {MainComponent} from '../main/main.component';
import {UpcomingEventsComponent} from '../upcoming-events/upcoming-events.component';
import {AuthGuard} from '../core/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'challenges', component: ChallengesComponent },
  //{ path: 'chat', component: ChatroomComponent },
  { path: 'comingsoon', component: ComingSoonComponent, canActivate: [AuthGuard] },
  { path: 'leaderboard', component: LeaderboardComponent },
  { path: 'colorcoded', component: EventpageComponent},
  { path: 'coming-soon', component: ComingSoonComponent},
  { path: 'cinema', component: CinemaComponent },
  { path: 'home', component: HomeComponent},
  //{ path: 'forms', component: FormsPageComponent},
  { path: 'actions', component: ActionsComponent},
  {path: 'main', component: MainComponent,
children: [
      {path: 'contact', component: ContactComponent},
      {path: 'faq', component: FAQComponent},
      {path: 'events', component: UpcomingEventsComponent},
      { path: '**',redirectTo: 'events' }

    ]
},
{ path: 'contact', redirectTo: '/main/contact' },
{ path: 'faq', redirectTo: '/main/faq' },
{ path: 'events', redirectTo: '/main/events' },
{ path: '**', redirectTo: '/home' }




];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }





